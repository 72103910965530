import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { Resource } from '@opentelemetry/resources'
import {
  MeterProvider,
  PeriodicExportingMetricReader,
} from '@opentelemetry/sdk-metrics'
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'
import { config } from '../config'

// import { diag, DiagConsoleLogger, DiagLogLevel } from '@opentelemetry/api'
// For troubleshooting, set the log level to DiagLogLevel.DEBUG
// diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.DEBUG)

const traceExporter = new OTLPTraceExporter({
  url: config.telemetry.traceEndpoint,
})
const metricExporter = new OTLPMetricExporter({
  url: config.telemetry.metricsEndpoint,
})

const resource = new Resource({
  [SemanticResourceAttributes.SERVICE_NAME]: `travellr-frontend-${config.env}`,
})

const tracerProvider = new WebTracerProvider({
  resource,
})
tracerProvider.addSpanProcessor(new BatchSpanProcessor(traceExporter))
tracerProvider.register({
  contextManager: new ZoneContextManager(),
})

const meterProvider = new MeterProvider({ resource })
const metricReader = new PeriodicExportingMetricReader({
  exporter: metricExporter,
})
meterProvider.addMetricReader(metricReader)

registerInstrumentations({
  tracerProvider,
  meterProvider,
  instrumentations: [getWebAutoInstrumentations()],
})
